import Footer from "./Footer";
import LandingPage from "./LandingPage/LandingPage";
import Desktop from "./Menu/DeskTop";
import Mobile from "./Menu/Mobile";

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ServiceDetailPage from "./Pages/ServiceDetailPage";
import SuccessfullRequestPage from "./Pages/SuccessfullRequestPage";
import ContactPage from "./Pages/ContactPage";
import AboutUs from "./Pages/AboutUs";
import DashBoard from "./AdminPanel/DashBoard";
import DB_Requests from "./AdminPanel/Requests/DB_Requests";
import DB_NewsLetter_Subscribers from "./AdminPanel/DB_NewsLetter_Subscribers";
import Careers from "./Pages/Careers";
import SingleReelPost from "./Pages/SingleReelPost";
import LatestPost from "./BlogPosts/LatestPost";
import BlogPosts from "./BlogPosts/BlogPosts";
import Posts from "./BlogPosts/Posts";
import UnderMaintenence from "./UnderMaintenence";
export default function Main(){
    return(
        <>
        <div className="Main" id="Main">
            <UnderMaintenence/>
            {/*<div className="Under"> This Website is Under Construction </div>
            <Desktop/>
            <Mobile/>
            
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={< LandingPage />} />
                    <Route exact path="/Services/:id" element={< ServiceDetailPage />} />
                    <Route exact path="/ContactUs" element={<ContactPage/>}/>
                    <Route exact path="/ContactUs/Success" element={<SuccessfullRequestPage/>}/>
                    <Route exact path="/AboutUs" element={<AboutUs/>}/>

                    <Route exact path="/Blog" element={<BlogPosts/>}/>
                    <Route exact path="/Blog/latest" element={<LatestPost/>}/>


                    <Route exact path="/Careers" element={<Careers/>}/>
                    <Route exact path="/Admin/DashBoard" element={<DashBoard/>}/>
                    <Route exact path="/Admin/DashBoard/Requests" element={<DashBoard childComponent={DB_Requests}/>}/>
                    <Route exact path="/Admin/DashBoard/NLSubscribers" element={<DashBoard childComponent={DB_NewsLetter_Subscribers}/>}/>
                    
                    <Route exact path="/blogger" element={<Posts/>}/>

                </Routes>
            </BrowserRouter>
            <Footer/>*/}
        </div>
        </>
    )
}